<template>
  <v-dialog
    v-if="!loading"
    v-model="dialogPostImage"
    @click:outside="$emit('update:dialogPostImage', false)"
    @keydown.esc="$emit('update:dialogPostImage', false)"
    max-width="600px"
  >
    <v-card class="pa-2">
      <v-img max-width="600px" :src="image">
        <div class="d-flex justify-end">
          <v-btn
            @click="$emit('update:dialogPostImage', false)"
            class="white--text"
            text
            fab
            small
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogPostImage",

  props: {
    dialogPostImage: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style></style>
